import React from 'react';
import { withNamespaces } from 'react-i18next';

import './ServicePageBody.scss';
import Api from '../../utils/api';

import NotAMemberComponent from '../NotAMemberComponent';
import logoImg from '../../assets/img/servicePage/logo.svg';
import PageHeaderTitle from '../PageHeaderTitle';

import img1 from '../../assets/img/protocols.svg';
import img2 from '../../assets/img/servicePage/group-7-copy.svg';
import img3 from '../../assets/img/nologs.svg';
import img4 from '../../assets/img/encryption.svg';
import img5 from '../../assets/img/new_ip-2.svg';
import img6 from '../../assets/img/servicePage/Port_forwarding.svg';
import img7 from '../../assets/img/anonine-landing/files.svg';

import rev1 from '../../assets/img/servicePage/sprite-logo@2x.jpg';
import rev2 from '../../assets/img/servicePage/logo-transparent-bestvpn-1504258566215@2x.jpg';
import ServicePageFeature from './ServicePageFeature';

class ServicePageBody extends React.Component {
  constructor(props) {
    super(props);

    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });
  }

  render() {
    const { t, brand } = this.props;

    const TopBlock = ({ imgSrc, title, text }) => (
      <div className='topBlock-service'>
        <div className='topBlock-service__img'>
          <img src={imgSrc} alt={title} />
        </div>
        <div className='topBlock-service__title'>
          {title}
        </div>
        <div className='topBlock-service__text' dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    );

    return (
      <div className='page-service'>
        <PageHeaderTitle brandName={brand.name} title={t('page_service:service')} />
        <div className='wrapper-service wrapper-service_bg_gray wrapper-service_borderBottom'>
          <div className='header-service'>
            <div className='innerWrapper-service'>
              <div className='header-service__grid'>
                <div className='header-service__image'>
                  <img src={logoImg} />
                </div>
                <div className='header-service__text'>
                  <h1 className='title-service_h1'>
                    {t('page_service:h1')}
                  </h1>
                  <div className='text-service' dangerouslySetInnerHTML={{ __html: t('page_service:text_1') }} />
                  <div className='topBlocks-service'>
                    <TopBlock
                      title={t('page_service:list_1_title')}
                      key='1'
                      text={t('page_service:list_1')}
                      imgSrc={img1}
                    />
                    <TopBlock
                      title={t('page_service:list_2_title')}
                      text={t('page_service:list_2_text')}
                      imgSrc={img2}
                      key='2'
                    />
                    <TopBlock
                      title={t('page_service:list_3_title')}
                      text={t('page_service:list_3_text')}
                      imgSrc={img3}
                      key='3'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='wrapper-service'>
          <div className='innerWrapper-service innerWrapper-service_mw_1024'>
            <ServicePageFeature
              title={t('page_service:block_1_title')}
              text={t('page_service:block_1_text')}
              src={img4}
              horizontal
            />
          </div>
        </div>
        <div className='wrapper-service'>
          <div className='innerWrapper-service innerWrapper-service_mw_1024'>
            <ServicePageFeature
              title={t('page_service:block_2_title')}
              text={t('page_service:block_2_text')}
              src={img5}
              horizontal
            />
          </div>
        </div>
        <div className='wrapper-service wireguard-protocol-wrapper'>
          <div className='innerWrapper-service innerWrapper-service_mw_1024'>
            <ServicePageFeature
              title={t('page_service:wire_guard_title')}
              text={t('page_service:wire_guard_text_1')}
              secondaryText={t('page_service:wire_guard_text_2')}
              buttonText={t('page_service:wire_guard_btn')}
              url={this.api.wireguardUrl()}
              src=''
            />
          </div>
        </div>
        <div className='wrapper-service wrapper-service_bg_gray'>
          <div className='innerWrapper-service'>
            <NotAMemberComponent
              brand={brand.name}
              orderUrl={this.api.orderUrl()}
              text={t('page_service:not_a_member')}
              buttonText={t('page_service:get_anonine')}
              nomask
              noBorder
              noPadding
            />
          </div>
        </div>
        <div className='wrapper-service'>
          <div className='innerWrapper-service innerWrapper-service_mw_1024'>
            <ServicePageFeature
              title={t('page_service:block_3_title')}
              text={t('page_service:block_3_text')}
              src={img6}
            />
          </div>
        </div>
        <div className='wrapper-service'>
          <div className='innerWrapper-service innerWrapper-service_mw_1024'>
            <ServicePageFeature
              title={t('page_service:block_4_title')}
              text={t('page_service:block_4_text')}
              reverse
              scaleImg={2}
              src={img7}
            />
          </div>
        </div>
        <div>
          <div className='innerWrapper-service'>
            <div className='reviews-service'>
              <div className='review-service'>
                <div className='text-service'>
                  {t('page_service:rev_1')}
                </div>
                <div className='review-service__logo'>
                  <img src={rev1} alt='VPN Ranks' />
                </div>
              </div>
              <div className='review-service'>
                <div className='text-service'>
                  {t('page_service:rev_2')}
                </div>
                <div className='review-service__logo'>
                  <img src={rev2} alt='Best VPN' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotAMemberComponent
          brand={brand.name}
          text={t('page_service:not_a_member')}
          buttonText={t('page_service:get_anonine')}
          orderUrl={this.api.orderUrl()}
        />
      </div>
    );
  }
}

export default withNamespaces()(ServicePageBody);
